import { useState } from "react";
import navMenuDef from "data/nav_menu_def.json";
import "./mobilenavmenu.css";

function NavMenuItem({
  id,
  title,
  url,
  active,
  submenu = false,
  clickHandler,
}) {
  return (
    <>
      <li
        onClick={clickHandler}
        className={`mobile-menu-item${active ? " hover" : ""}`}
        id={id}
      >
        <a
          className={`mobile-menu${typeof id === "string" ? "-sub" : ""}-link`}
          href={url}
          style={submenu && !active ? { pointerEvents: "none" } : {}}
        >
          {title}
          {submenu ? <span className="submenu-indicator"> </span> : ""}
        </a>
      </li>
    </>
  );
}

export default function NavMenu({ open = false }) {
  const [selectedMenu, setSelectedMenu] = useState(-1);

  function onClickHandler(e) {
    setSelectedMenu(e.currentTarget.id);
  }

  return (
    <div className={`mobile-nav${open ? " open" : ""}`}>
      <ul className="mobile-ul">
        {navMenuDef.map((el, i) => {
          const hasSubMenu = el.submenu.length > 0;
          const isSelected = Number(selectedMenu) === i;

          return (
            <>
              <NavMenuItem
                key={`mobile-nav-${i}`}
                id={i}
                title={el.title}
                url={el.url}
                active={isSelected}
                submenu={hasSubMenu}
                clickHandler={(e) => onClickHandler(e)}
              />
              {isSelected && hasSubMenu
                ? navMenuDef[selectedMenu].submenu.map((el, i) => {
                    return (
                      <NavMenuItem
                        key={`mobile-nav-sub-${selectedMenu}${i}`}
                        id={`${selectedMenu}${i}`}
                        pos={0}
                        title={el.title}
                        url={el.url}
                      />
                    );
                  })
                : ""}
            </>
          );
        })}
      </ul>
    </div>
  );
}
