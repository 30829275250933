const { chateau_images } = require("./chateau_image_manifest");
const { rustic_images } = require("./rustic_image_manifest");
const { silo_images } = require("./silo_image_manifest");
const { homestead_images } = require("./homestead_image_manifest");

export const floorplanManifest = [
  {
    planId: "OCH-004",
    planName: "The Château",
    desc: "Discover the enchanting Château Floor Plan from O'Neal Custom Homes, where timeless French architecture meets the charm of modern farmhouse design. This stunning residence features an impressive 2,525 conditioned square feet of thoughtfully designed living space, boasting soaring 10-foot ceilings that enhance the feeling of openness and elegance. The spacious layout seamlessly integrates comfort and style, making it perfect for both relaxing family moments and entertaining guests. With a convenient 3-car garage, this home offers both functionality and aesthetic appeal. Experience the perfect blend of sophistication and warmth in the Château Floor Plan—your dream home awaits!",
    beds: 4,
    baths: [3, 3], //[Total, #Full]
    images: chateau_images,
    videos: [],
    sqft: [2525, 560, 835, 3920], //[liveable, porch, garage, slab]
  },
  {
    planId: "OCH-005c",
    planName: "The Homestead",
    desc: "Discover the charm of The Homestead floor plan by O'Neal Custom Homes, where modern sophistication meets classic farmhouse architecture. Spanning 2,490 square feet, this exquisite home features soaring high ceilings and an open layout that invites natural light to dance throughout the living spaces. Enjoy the convenience of a three-car garage, perfect for families and hobbyists alike.\n\nThe Homestead embodies a timeless design that takes you back to simpler times, combining warmth and elegance in every corner. With its inviting spaces and thoughtful details, this home is not just a place to live—it's a sanctuary where cherished memories are made. Experience the perfect blend of modern comfort and rustic charm in The Homestead, where every day feels like a retreat.",
    beds: 4,
    baths: [3, 3], //[Total, #Full]
    images: homestead_images,
    videos: ["c767f30d2e308c5c715593f98d3d1e66"],
    sqft: [2490, 470, 790, 3750], //[liveable, porch, garage, slab]
  },
  {
    planId: "OCH-006",
    planName: "The Rustic",
    desc: "Welcome to the Rustic Floor Plan from O'Neal Custom Homes, where rustic country charm meets timeless elegance. This captivating design showcases exposed timbers and stunning stone accents, harmoniously blended with classic colonial elements to create a warm and inviting atmosphere. Spanning 2,090 conditioned square feet, this thoughtfully laid-out home emphasizes efficient living without sacrificing style, featuring soaring 10-foot ceilings that enhance its spacious feel. With a convenient 3-car garage, the Rustic Floor Plan perfectly balances functionality and aesthetic appeal. Embrace the beauty of country living in a home that truly reflects your lifestyle!",
    beds: 3,
    baths: [2, 2], //[Total, #Full]
    images: rustic_images,
    videos: [],
    sqft: [2090, 490, 685, 3265], //[liveable, porch, garage, slab]
  },
  {
    planId: "OCH-007a",
    planName: "The Silo",
    desc: "Introducing the Silo Floor Plan from O'Neal Custom Homes—a truly unique residence that redefines contemporary living with its expansive layout of 2,745 conditioned square feet. The standout feature of this home is its striking full metal or stone silo at the front, setting it apart from anything else on the market. The Silo Floor Plan masterfully blends farmhouse and industrial architecture, harmonizing modern elements with old-world charm. Inside, you'll find beautiful exposed beams that enhance the open space, along with a spacious back porch perfect for relaxing or entertaining. With a convenient 3-car garage, this home offers both style and functionality. Experience the perfect fusion of tradition and innovation in the Silo Floor Plan—where your dream home becomes a reality!",
    beds: 4,
    baths: [3, 3], //[Total, #Full]
    images: silo_images,
    videos: [],
    sqft: [2745, 405, 790, 3940], //[liveable, porch, garage, slab]
  },
];
