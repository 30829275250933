import "./wherewebuild.css";

import Leaflet from "external/leaflet/Leaflet";
import BackgroundImg from "components/BackgroundImg/BackgroundImg";

export default function WhereWeBuildPage({ compact }) {
  return (
    <div className="wwb-page">
      <div className="wwb-landing">
        <div className="wwb-landing-content">
          <span className="wwb-banner-text">
            Where we <span>Build</span>
          </span>
        </div>
        <BackgroundImg idOverride={7} compact={compact} />
      </div>
      <div className="wwb-available-homes">
        <div
          className={`wwb-available-homes-banner${compact ? " compact" : ""}`}
        >
          <span>Where we build</span>
          <div className="wwb-available-homes-banner-divider"></div>
          <span>
            ​Texas Builder O'neal Custom Homes primarily builds in Salado due to
            its top-rated school system and wonderful small town country feel.
            We also build in Georgetown and surrounding areas. If you are
            interested in building in another area, message us to see if we can
            accommodate you.
          </span>
        </div>
      </div>
      <div className={`wwb-map-container${compact ? " compact" : ""}`}>
        <Leaflet />
        <span style={{ float: "right", fontSize: "0.8rem" }}>
          Note that these bounds are an approximation
        </span>
      </div>
    </div>
  );
}
