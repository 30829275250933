import { useState } from "react";

import React from "react";
import Slider from "react-slick";
import { Stream } from "@cloudflare/stream-react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import "./floorplan.css";
import { floorplanManifest } from "data/floorplans/floorplan_manifest.js";
import BackgroundImg from "components/BackgroundImg/BackgroundImg";
import { PHONE_NUMBER, IMAGES_API_URL } from "commons/constants";

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "green" }}
      onClick={onClick}
    />
  );
}

function SimpleSlider({ compact, onClickHandler }) {
  var settings = {
    infinite: false,
    slidesToShow: compact ? 1 : 3,
    speed: 500,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
  };
  return (
    <Slider {...settings}>
      {floorplanManifest.map((el, i) => {
        return (
          <FloorplanTile
            key={i}
            id={i}
            data={el}
            onClickHandler={onClickHandler}
            compact={compact}
          />
        );
      })}
    </Slider>
  );
}

function FloorplanTile({ id, data, onClickHandler, compact }) {
  return (
    <div
      className="floorplan-tile"
      onClick={() => onClickHandler(id)}
      style={{ fontSize: "1rem", fontFamily: "var(--font-roboto)" }}
    >
      <div>
        <span style={{ paddingBottom: "0.3rem" }}>{data.planName}</span>

        <img
          src={`${IMAGES_API_URL}size=${
            compact ? "hero" : "tile"
          }&compact=${compact}&imageId=${data.images[0].src}`}
          style={{ width: "100%", height: "100%", pointerEvents: "none" }}
          alt=""
        />
        <div
          style={{
            display: "grid",
            padding: "1rem",
          }}
        >
          <span style={{ gridRow: "1", gridColumn: "1" }}>
            <span>
              Beds:
              {"  "}
              {data.beds}
            </span>
          </span>
          <span style={{ gridRow: "1", gridColumn: "2" }}>
            <span>
              Bath:
              {"  "}
              <span
                className="hint--top"
                aria-label={`${data.baths[1]} Full Baths`}
                style={{ textDecoration: "underline dotted" }}
              >
                {" "}
                {data.baths[0]}{" "}
              </span>
            </span>
          </span>
          <span style={{ gridRow: "1", gridColumn: "3" }}>
            <span>
              sqft:
              {"  "}
              {data.sqft[0]}ft
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

function FloorplanDetails({
  floorplanNum,
  compact,
  index,
  setIndex,
  onClickHandler,
}) {
  const gridRVals = compact
    ? ["1 / 4", "4", "4", "4", "4"]
    : ["1 / 3", "1", "1", "2", "2"];
  const gridCVals = compact
    ? ["1 / 5", "1", "2", "3", "4"]
    : ["1 / 3", "3", "4", "3", "4"];

  const floorplanVideo = floorplanManifest[floorplanNum].videos[0];
  const vidOffset = floorplanVideo ? -1 : 0;

  return (
    <>
      <div id="floorplan-details" className={`${compact ? "compact" : ""}`}>
        <button id="close-details-btn" onClick={() => onClickHandler(-1)}>
          X
        </button>
        <div id="floorplan-images">
          {floorplanVideo && (
            <div
              id="video-stream"
              style={{ gridRow: gridRVals[0], gridColumn: gridCVals[0] }}
            >
              <Stream
                src={floorplanVideo}
                controls
                responsive
                poster="https://logo.onealcustomhomes.com/"
                preload="auto"
                volume={0.5}
              />
            </div>
          )}
          {gridRVals.map((el, i) => {
            if (floorplanVideo && i === 0) {
              return "";
            } else
              return (
                <div
                  key={`details-${i}`}
                  style={{ gridRow: el, gridColumn: gridCVals[i] }}
                  onClick={() => setIndex(i + vidOffset)}
                >
                  <img
                    src={`${IMAGES_API_URL}size=tile&compact=${compact}&imageId=${
                      floorplanManifest[floorplanNum].images[i + vidOffset].src
                    }`}
                    alt=""
                  />
                  {!compact && i === gridRVals.length - 1 ? (
                    <button
                      id="more-images-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIndex(i + vidOffset + 1);
                      }}
                    >
                      View more images
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              );
          })}
        </div>
        <div id="floorplan-specs" className={`${compact ? "compact" : ""}`}>
          {/* <span id="floorplan-price">// Price Range //</span> */}
          <span id="floorplan-name">
            {floorplanManifest[floorplanNum].planName}
          </span>
          <span id="floorplan-beds">
            <span>Beds:</span>
            {"  "}
            {floorplanManifest[floorplanNum].beds}
          </span>
          <span id="floorplan-baths">
            <span>Baths:</span>{" "}
            <span
              className="hint--right"
              data-hint={`${floorplanManifest[floorplanNum].baths[1]} Full Baths`}
            >
              {" "}
              {floorplanManifest[floorplanNum].baths[0]}{" "}
            </span>
          </span>
          <span id="floorplan-feet" className={`${compact ? "compact" : ""}`}>
            <span>sqft: </span>
            {"  "}
            <span
              className="hint--right"
              aria-label={`Liveable Interior: ${floorplanManifest[floorplanNum].sqft[0]}ft\u000ACovered Patio: ${floorplanManifest[floorplanNum].sqft[1]}ft\u000AGarage: ${floorplanManifest[floorplanNum].sqft[2]}ft`}
            >
              {floorplanManifest[floorplanNum].sqft[0]}ft
            </span>
          </span>
          <span className="floorplan-line" />
          <div id="floorplan-specs-blurb" style={{ whiteSpace: "pre-line" }}>
            {floorplanManifest[floorplanNum].desc}
          </div>
          <div
            id="floorplan-specs-contact"
            className={`${compact ? "compact" : ""}`}
          >
            <span style={{ fontSize: "1.3rem" }}>
              Interested in this floorplan?
            </span>
            <h1
              style={{
                fontSize: "2rem",
                color: "var(--color-green)",
                margin: "none",
              }}
            >
              Let us know
            </h1>
            <span style={{ fontSize: "1.1rem" }}>
              Call us at <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a> or
              click the button {compact ? "to the right" : "below"}
            </span>
          </div>
          <div
            id="floorplan-specs-contact-btn"
            className={`${compact ? "compact" : ""}`}
          >
            <a
              href={`/contact?fp=${floorplanManifest[floorplanNum].planName
                .split(" ")[1]
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}`}
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <Lightbox
        slides={floorplanManifest[floorplanNum].images.map((el) => {
          return {
            src:
              IMAGES_API_URL +
              "size=lightbox&compact=" +
              compact +
              "&imageId=" +
              el.src,
            width: el.width,
            height: el.height,
          };
        })}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        carousel={{ finite: true }}
        plugins={[Fullscreen, Thumbnails, Zoom]}
      />
    </>
  );
}

export default function FloorplansPage({ compact }) {
  const [floorplanNum, setFloorplanNum] = useState(-1);
  const [index, setIndex] = useState(-1);

  function handleTileClick(t) {
    if (t === floorplanNum) setFloorplanNum(-1);
    else setFloorplanNum(t);
  }

  return (
    <div className="fp-page">
      <div className="fp-landing">
        <div className="fp-landing-content">
          <span className="fp-banner-text">
            Our <span>Plans</span>
          </span>
        </div>
        <BackgroundImg idOverride={3} compact={compact} />
      </div>
      <div className="fp-floorplans">
        <div className={`fp-floorplans-banner${compact ? " compact" : ""}`}>
          <span>Floorplans to begin</span>
          <div className="fp-floorplans-banner-divider"></div>
          <span>
            When building your own home, it can feel like a daunting task. Where
            do I start? At O'Neal Custom Homes, we offer several floorplans for
            you to easily find your footing with a starting place.
          </span>
        </div>
      </div>
      <div id="floorplan-container">
        <div
          id="floorplan-slider"
          style={{
            width: "90%",
            margin: "auto",
          }}
        >
          <SimpleSlider
            compact={compact}
            onClickHandler={(i) => handleTileClick(i)}
          />
        </div>
      </div>
      <div id="detail-banner">
        {floorplanNum === -1 ? (
          <span>Select a floorplan to view more details below</span>
        ) : (
          <span>{floorplanManifest[floorplanNum].planName} details:</span>
        )}
      </div>
      {floorplanNum !== -1 && (
        <FloorplanDetails
          compact={compact}
          index={index}
          setIndex={(i) => setIndex(i)}
          floorplanNum={floorplanNum}
          onClickHandler={(i) => handleTileClick(i)}
        />
      )}
    </div>
  );
}
