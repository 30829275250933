//Contact information
export const PHONE_NUMBER = "512-749-3064";
export const EMAIL = "onealcustomhomestx@gmail.com";
export const LOCATION = "Salado, TX";

//Public Key
export const WEB3FORM_EMAIL_KEY = "a1432667-d2dd-46dd-99df-2d415820497a";
export const WEB3FORM_EMAIL_KEY_DEV = "b87f566a-af97-4da1-b7f5-79e21a0af607";

//Image API
export const IMAGES_API_URL = "https://images.onealcustomhomes.com/?";
export const LOGO_API_URL = "https://logo.onealcustomhomes.com/?";

//View width constants
export const compactViewThreshold = 820;
export const mobileNavThreshold = 740;
