import { useState } from "react";

import ContactForm from "components/ContactForm/ContactForm";
// import BackgroundImg from "components/BackgroundImg/BackgroundImg";

import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import "./aboutus.css";
import { IMAGES_API_URL } from "commons/constants";

import BackgroundImg from "components/BackgroundImg/BackgroundImg";

// import classic from "data/image_manifests/about_us_image_manifest2";
// import modern from "data/image_manifests/about_us_image_manifest1";

const { classic } = require("data/image_manifests/about_us_image_manifest2");
const { modern } = require("data/image_manifests/about_us_image_manifest1");

export default function AboutUsPage({ compact }) {
  const [index, setIndex] = useState(-1);

  return (
    <div className="au-page">
      <div className="au-landing">
        <div className="au-landing-content">
          <span className="au-banner-text">
            About <span>Us</span>
          </span>
        </div>
        <BackgroundImg idOverride={0} compact={compact} />
      </div>
      <div id="owner-intro">
        <h1 id="owner-name">Bryan Summers</h1>
        <img
          id="owner-portrait"
          src={`${IMAGES_API_URL}width=1080&imageId=testimonials/DowningTexas.webp`}
          alt=""
        />
        <span id="owner-blurb">
          Hey, <span>Bryan Summers</span> here, owner and founder of O'Neal
          Custom Homes. First and foremost, I’d like to thank you for stopping
          by our website and seeing what we have to offer. When I started this
          company, I had the simple desire to be different from other builders.
          I've been in your shoes and I know first-hand how confusing and
          stressful the process of building your home can be. Let me help you on
          your journey!
          <br />
        </span>
      </div>
      <div id="oneal-rustic" className={compact ? "compact" : ""}>
        <div id="oneal-rustic-left">
          <span id="oneal-rustic-header">Built By You</span>
          <span id="oneal-rustic-text">
            At O'Neal Custom Homes, we believe that a home is more than just a
            structure—it's a reflection of your unique lifestyle and
            aspirations. We strive to to utilize the finest materials and the
            latest techniques, ensuring that every home we build is not only
            beautiful but also built to stand the test of time. Your home will
            be the talk of, and one-of-a-kind, for the neighborhood.
          </span>
        </div>
        <div id="oneal-rustic-right">
          <RowsPhotoAlbum
            photos={classic.map((el) => {
              return {
                src:
                  IMAGES_API_URL +
                  "size=tile&compact=" +
                  compact +
                  "&imageId=" +
                  el.src,
                width: el.width,
                height: el.height,
              };
            })}
            onClick={({ index }) => setIndex(index)}
          />
        </div>
      </div>
      <div id="oneal-smart" className={compact ? "compact" : ""}>
        <div id="oneal-smart-left">
          <RowsPhotoAlbum
            photos={modern.map((el) => {
              return {
                src:
                  IMAGES_API_URL +
                  "size=tile&compact=" +
                  compact +
                  "&imageId=" +
                  el.src,
                width: el.width,
                height: el.height,
              };
            })}
            onClick={({ index }) => setIndex(index)}
          />
        </div>
        <div id="oneal-smart-right">
          <span id="oneal-smart-header">Built for You</span>
          <span id="oneal-smart-text">
            While we build our homes to maintain a vintage feeling, that doesn't
            mean it goes without the conveniences of a modern home. We make
            certain that we make use of some of the latest technologies. From
            water-faucets that visually display when your water is hot, to smart
            garage doors that you can control from anywhere, we strive to build
            a home smart enough to keep up with today's techonology.
          </span>
        </div>
      </div>
      <div className="au-contact-container">
        <span>
          Experience the difference of true craftsmanship with O'Neal Custom
          Homes, where your vision becomes our mission. We look forward to
          partnering with and working alongside you to build a home you'll
          cherish for years to come.
        </span>
        <div className="au-contact-form">
          <ContactForm />
        </div>
      </div>
      <Lightbox
        slides={[...classic, ...modern].map((el) => {
          return {
            src:
              IMAGES_API_URL +
              "size=lightbox&compact=" +
              compact +
              "&imageId=" +
              el.src,
            width: el.width,
            height: el.height,
          };
        })}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        carousel={{ finite: true }}
        plugins={[Fullscreen, Thumbnails, Zoom]}
      />
    </div>
  );
}
