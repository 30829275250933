import {
  FaPhoneAlt,
  FaEnvelope,
  FaInstagram,
  FaFacebookSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import { EMAIL, PHONE_NUMBER } from "commons/constants";

import ContactForm from "components/ContactForm/ContactForm";
import BackgroundImg from "components/BackgroundImg/BackgroundImg";

import "./contactus.css";

export default function ContactUsPage({ compact }) {
  return (
    <div className="cu-page">
      <div className="cu-landing">
        <div className={`cu-landing-content${compact ? " compact" : ""}`}>
          <div className={`cu-banner-call${compact ? " compact" : ""}`}>
            <div className="cu-phone-icon">
              <a href={`tel:${PHONE_NUMBER}`}>
                <FaPhoneAlt size={40} color="var(--color-green)" />
              </a>
            </div>
            <span>Call Us</span>
            <span>
              Tell us your needs and we'll contact you with more information!
            </span>
            <span>
              {" "}
              <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
            </span>
          </div>
          <div className={`cu-banner-email${compact ? " compact" : ""}`}>
            <div className="cu-email-icon">
              <a href={`mailto:${EMAIL}`}>
                <FaEnvelope size={40} color="var(--color-green)" />
              </a>
            </div>
            <span>Email Us</span>
            <span>
              For general inquiries and questions, contact us via email.
            </span>
            <span>
              <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </span>
          </div>
        </div>
        <BackgroundImg idOverride={3} compact={compact} />
      </div>
      <div className="cu-social-media">
        <span>Stay connected with us.</span>
        <a
          href="https://www.instagram.com/onealcustomhomesllc/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram size="100" style={{ color: "var(--color-green)" }} />
        </a>
        <a
          href="https://twitter.com/onealcustomhome"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitterSquare size="100" style={{ color: "var(--color-green)" }} />
        </a>
        <a
          href="https://www.facebook.com/onealcustomhomes/"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookSquare
            size="100"
            style={{ color: "var(--color-green)" }}
          />
        </a>
      </div>
      <div className={`cu-contact-container${compact ? " compact" : ""}`}>
        <span>
          Contact <span>us</span>
        </span>
        <span>
          Bryan Summers can be contacted directly via cell phone at{" "}
          {PHONE_NUMBER} or by filling out the form below.
        </span>
        <div className="cu-contact-form">{<ContactForm />}</div>
        {compact ? "" : <div className="cu-contact-side-image"></div>}
      </div>
    </div>
  );
}
