import { useState, useEffect } from "react";

import { IMAGES_API_URL } from "commons/constants";
import "./background-img.css";

const { backgrounds } = require("data/image_manifests/header_images_manifest");

export default function BackgroundImg({ idOverride = 6, compact }) {
  const [curImg, setCurImg] = useState(idOverride);
  // const [nextImg, setNextImg] = useState(1);

  return (
    <div id="rotating-background-container">
      <div id="background-1" className="rotating-background">
        <img
          src={`${IMAGES_API_URL}size=hero&compact=${compact}&imageId=${backgrounds[curImg]}`}
          alt=""
        />
      </div>
      {/* <div id="background-2" className="rotating-background">
        <img
          src={IMAGES_API_URL + "width=1080&imageId=" + backgrounds[nextImg]}
          alt=""
        />
      </div> */}
    </div>
  );
}
