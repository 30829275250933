import "./availablehomes.css";
import homeListings from "./availablehomes.json";
import Tile from "components/Tile/Tile.js";
import BackgroundImg from "components/BackgroundImg/BackgroundImg";

export default function AvailableHomesPage({ compact }) {
  return (
    <div className="ah-page">
      <div className="ah-landing">
        <div className="ah-landing-content">
          <span className="ad-banner-text">
            Available <span>Homes</span>
          </span>
        </div>
        <BackgroundImg idOverride={1} compact={compact} />
      </div>
      <div className="ah-available-homes">
        <div className="ah-available-homes-banner">
          <span>Available Properties from O'neal Custom Homes</span>
          <div className="ah-available-homes-banner-divider"></div>
        </div>
        <div className="ah-listings">
          {
            //Conditional Rendering for Available Homes listings - Available Homes template TODO
            homeListings.length > 0 ? (
              <span>AVAILABLE HOMES TEMPLATE PLACEHOLDER</span>
            ) : (
              // Default Messaging when no homes available
              <span>
                There are currently no Spec Homes.{" "}
                {/* <span style={{ fontStyle: "italic" }}>SOLD</span> */}
                We are now taking orders for Custom Homes!
              </span>
            )
          }
        </div>
      </div>
      <div className="ah-explanation">
        <span>What is the difference between a Spec home and Custom home?</span>
        <div className={`ah-explanation-spec${compact ? " compact" : ""}`}>
          <Tile
            key="spec-explanation"
            number={0}
            alignment="center"
            header="Spec Home"
          >
            <span>
              A Speculative &#40;Spec&#41; Home is a house where all fixtures,
              tile and design decisions are handpicked by O’Neal Custom Homes.
              These items, which include colors, countertops, cabinets styles
              and more, are carefully chosen to make the home stand apart and
              look truly custom.
              <br />
              <br />
              If you want a home that is unique, not cookie cutter, has custom
              elements with <span style={{ fontStyle: "italic" }}>wow</span>
              -factor, but do not want to wait for a 6 month build, a Spec house
              might be for you.
            </span>
          </Tile>
        </div>

        <div className={`ah-explanation-custom${compact ? " compact" : ""}`}>
          <Tile
            key="custom-explanation"
            number={0}
            alignment="center"
            header="Custom Home"
          >
            <span>
              A Custom Home gets you involved right from the start. O'Neal
              Custom Homes will work hand-in-hand with you through every step of
              the building process. From designing your entryway, to picking the
              countertops for each of your bathrooms, a custom home will be
              one-of-a-kind, tailored to you and your dreams.
            </span>
          </Tile>
        </div>
      </div>
    </div>
  );
}
