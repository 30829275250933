import React from "react";
import { MapContainer, TileLayer, Polygon } from "react-leaflet";

import "leaflet/dist/leaflet.css";

// [Lat, Long]
var bounds = [
  [31.089632843804328, -97.38923812762582],
  [31.13907200701638, -97.3294801533143],
  [31.088072121594855, -97.30926935298768],
  [30.646134287979727, -97.44033429246149],
  [30.570450630563172, -97.41125393701195],
  [30.517275057731545, -97.6862251202868],
  [30.758603660752943, -98.22678495849378],
  [31.063044538289887, -98.1866318072943],
  [31.11586132790297, -97.75785582160164],
  [31.048760540596668, -97.47096249959223],
];

const Map = () => {
  return (
    <MapContainer center={[30.85, -97.74]} zoom={9} style={{ height: "400px" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors, Tiles style by <a href='https://www.hotosm.org/' target='_blank'>Humanitarian OpenStreetMap Team</a> hosted by <a href='https://openstreetmap.fr/' target='_blank'>OpenStreetMap France</a>"
      />
      <Polygon pathOptions={{ color: "red" }} positions={bounds} />
    </MapContainer>
  );
};

export default Map;
